<template lang="pug">
  v-menu(v-model="menuShowing" :close-on-content-click="false")
    template(v-slot:activator="{ on }")
      v-icon(v-on="on" small).copy-arrow mdi-menu-down
    v-card
      v-card-title(data-test="c-accept-title") Course date filter
      v-card-text
        fieldDatepicker(
          v-model="date_from"
          label="Course date from"
          :min="currentDate"
          :hide-details="false"
          :error="date_from_error"
        )
        fieldDatepicker(
          v-model="date_to"
          label="Course date to"
          :min="currentDate"
          :hide-details="false"
          :error="date_to_error"
        )
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="menuShowing = false") Close
        v-btn(color="success" text @click="submitMainDate") Apply
</template>

<script>
  import { convertToDefaultBackendFormat, DDMMYYYYFormatting } from '@/util'
  import Dayjs from 'dayjs'

  const DATE_REGEXP_FORMAT = /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$)/;

  export default {
    props: {
      date_before: String,
      date_after: String
    },
    data: () => ({
      date_from_error: false,
      date_to_error: false,
      date_from: '',
      date_to: '',
      menuShowing: false,
      currentDate: convertToDefaultBackendFormat(new Date(Date.now())),
    }),
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.date_from = this.date_after ? DDMMYYYYFormatting(this.date_after) : '';
        this.date_to = this.date_before ? DDMMYYYYFormatting(this.date_before) : '';
      },
      submitMainDate() {
        this.validateDate();

        if (!this.date_from_error && !this.date_to_error && !this.isDateSimilarToPrevious()) {
          this.$emit('change', {
            date_from: DDMMYYYYFormatting(this.date_from),
            date_to: DDMMYYYYFormatting(this.date_to)
          });
          this.menuShowing = false;
        }
        if (!this.date_from_error && !this.date_to_error) {
          this.menuShowing = false;
        }
      },
      isDateSimilarToPrevious() {
        if ((!this.date_after && !this.date_from.length) || (!this.date_before && !this.date_to.length) ) return true;
        return DDMMYYYYFormatting(this.date_after) === this.date_from &&
               DDMMYYYYFormatting(this.date_before) === this.date_to
      },
      validateDate() {
        this.date_from_error = false;
        this.date_to_error = false;
        if (!this.date_from.length && !this.date_to.length) return;
        this.date_from_error = !DATE_REGEXP_FORMAT.test(this.date_from);
        this.date_to_error = !DATE_REGEXP_FORMAT.test(this.date_to);
      }
    },
    components: {
      fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue')
    }
  }
</script>